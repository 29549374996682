/* eslint-disable react/no-array-index-key */
import React from 'react';
import Glide from '@glidejs/glide';

// components
import Arrow from '@src/svgs/arrow-black.svg';

// styles
import './glide.scss';

export default class Carousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
        };
    }

    componentDidMount() {
        const { enabled } = this.props;
        if (enabled) {
            this.setState({ id: `glide-${Math.ceil(Math.random() * 100)}` }, this.initializeGlider);
        }
    }

    componentDidUpdate(newProps) {
        const { options, enabled } = this.props;

        if (options.startAt !== newProps.options.startAt) {
            this.slider.go(`=${newProps.options.startAt}`);
        }

        if (enabled !== newProps.enabled) {
            if (enabled) {
                this.init();
            } else {
                this.slider.destroy();
            }
        }
    }

    initializeGlider = () => {
        const { id } = this.state;
        const { enabled, options } = this.props;
        this.slider = new Glide(`#${id}`, options);

        if (enabled) {
            this.slider.mount();
        } else {
            this.slider.disable();
        }
    };

    init() {
        this.setState({ id: `glide-${Math.ceil(Math.random() * 100)}` }, this.initializeGlider);
    }

    render() {
        const { id } = this.state;
        const { children, arrows, bullets, gap, full } = this.props;

        const bulletsEl = (
            <div
                className={`glide__bullets ${
                    full ? 'glide__bullets--full' : ''
                } flex flex-wrap items-center justify-center`}
                style={{ width: '90vw' }}
                data-glide-el="controls[nav]">
                {children.map((slide, index) => (
                    <button
                        type="button"
                        key={index}
                        aria-label="Slide selector"
                        className="glide__bullet"
                        data-glide-dir={`=${index}`}
                        title={index}
                    />
                ))}
            </div>
        );

        const arrowsEl = (
            <div className="glide__arrows purple flex justify-between" data-glide-el="controls">
                <button
                    type="button"
                    className="glide__arrow glide__arrow--left link"
                    style={{ transform: 'rotate(90deg)' }}
                    data-glide-dir="<"
                    title="start">
                    <span className="sr-only">previous</span>
                    <Arrow />
                </button>
                <button
                    type="button"
                    className="glide__arrow glide__arrow--right link"
                    style={{ transform: 'rotate(-90deg)' }}
                    data-glide-dir=">"
                    title="end">
                    <span className="sr-only">next</span>
                    <Arrow />
                </button>
            </div>
        );

        return (
            <div
                className={`glide relative z-1 ${full ? 'glide--full' : ''} ${
                    bullets ? 'glide--spacing' : ''
                }`}
                id={id}>
                <div className="glide__track" data-glide-el="track">
                    <div className={`flex ${gap ? 'glide__track-gap' : ''}`}>
                        {children.map((slide, index) =>
                            React.cloneElement(slide, {
                                key: index,
                                className: `${slide.props.className}`,
                            })
                        )}
                    </div>
                </div>

                {arrows && children.length > 1 ? arrowsEl : null}
                {bullets && children.length > 1 ? bulletsEl : null}
            </div>
        );
    }
}
